import { InjectionToken, inject } from '@angular/core';
const WINDOW = new InjectionToken('An abstraction over global window object', {
  factory: () => window
});
const LOCATION = new InjectionToken('An abstraction over window.location object', {
  factory: () => inject(WINDOW).location
});
const NAVIGATOR = new InjectionToken('An abstraction over window.navigator object', {
  factory: () => inject(WINDOW).navigator
});

/**
 * Generated bundle index. Do not edit.
 */

export { LOCATION, NAVIGATOR, WINDOW };
