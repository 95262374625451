/**
 * This file is required for building this "empty" package.
 */
const VERSION = '3.0.0';

/**
 * Generated bundle index. Do not edit.
 */

export { VERSION };
